<template>
  <div class="register st-wrapper" ref="register">
    <!-- 车辆信息 -->
    <div class="car-info">
      <div class="info-title">
        <van-badge color="none">
          <span> 车辆信息</span>
        </van-badge>
      </div>
      <div class="card">
        <div class="card-field" required data-label="shop_name">
          <div class="name"><i>*</i> <span>商户</span></div>
          <input v-model="sendData.shop_name" placeholder="请输入" readonly />
        </div>
        <div
          class="card-field"
          @click="plateVisible = true"
          required
          data-label="license_plate_number"
        >
          <div class="name"><i>*</i> <span>车牌</span></div>
          <input
            v-model="sendData.license_plate_number"
            placeholder="请输入"
            readonly
          />
        </div>
        <div
          class="card-field"
          required
          data-label="rough_weight"
          data-type="number"
        >
          <div class="name"><i>*</i> <span>毛重(kg)</span></div>
          <input
            type="number"
            v-model.number="sendData.rough_weight"
            @input="checkout('rough_weight')"
            placeholder="请输入"
          />
        </div>
        <div class="card-field">
          <div class="name"><i></i> <span>收费(元)</span></div>
          <input
            type="number"
            v-model.number="sendData.charge"
            placeholder="请输入"
            @input="checkout('charge')"
          />
        </div>
      </div>
    </div>
    <!-- 货物信息 -->
    <div class="goods-info">
      <div class="info-title">
        <van-badge color="none">
          <span> 货物信息</span>
          <template #content> * </template>
        </van-badge>
      </div>
      <div class="add-goods-btns">
        <div class="add-btn meat" @click="goGoods(0)">
          <van-icon class="icon" name="plus" color="#1BD49D" />
          <span> 肉类</span>
        </div>
        <div class="add-btn vegetable" @click="goGoods(1)">
          <van-icon class="icon" name="plus" color="#1BD49D" />
          <span> 蔬菜</span>
        </div>
      </div>
      <div class="card-list">
        <div
          class="card"
          v-for="(goods, index) in sendData.source_list"
          :key="index"
        >
          <div class="head">
            <span class="title">{{ goods.source_goods_name }}</span>
            <img
              @click="handleDelGoods(index)"
              class="del-icon"
              src="../../../assets/images/icon/delete-item.png"
              alt=""
            />
          </div>
          <div class="body" @click="handleModifyGoods(goods, index)">
            <div class="list">
              <p>
                <span class="label">进场重量(kg):</span>
                <span class="text">{{ goods.shipment }}</span>
              </p>
              <p>
                <span class="label">产地:</span
                ><span class="text">{{ goods.place_of_origin }}</span>
              </p>
              <p>
                <span class="label" v-if="goods.type === 'veg' ? true : false"
                  >产地检疫证号:</span
                >
                <span class="label" v-else>动物检疫合格证:</span>
                <span class="text">{{ goods.inspection_and_quarantine }}</span>
              </p>
            </div>
            <div class="modify">
              <div class="icon">
                <img
                  src="../../../assets/images/icon/register_goods-modify.png"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer-button text="完成" @confirm="confirm" />

    <van-popup v-model="plateVisible" round position="bottom" closeable>
      <plateNumber @confim="plateConfim" />
    </van-popup>
  </div>
</template>

<script>
import { Badge, Dialog, Icon, Cell, Popup } from 'vant'
import FooterButton from '../../../components/footer/button/Index'
import PlateNumber from '../plateNumber/Index'
export default {
  name: 'EntryRegister',
  data () {
    return {
      plateVisible: false,
      sendData: { // 接口 发送数据
        charge: '', // 收费 单位元
        farmers_markets_id: null, // 市场id
        farmers_market_goods_id: null, // 农贸市场商品id
        farmers_market_merchant_id: null, // 农贸市场商户id
        license_plate_number: '', // 车牌号
        rough_weight: '', // 毛重 kg单位
        shop_id: null, // 店铺id
        shop_name: '', // 店铺名
        source_list: [], // 货物信息列表
        cart_id: null, // 车辆信息表id
        create_time: '', // 创建时间
        farmers_markets_name: '', // 市场名称
        name_list: '' // 包含货物名字
      }
    }
  },
  components: {
    [Badge.name]: Badge,
    [Cell.name]: Cell,
    [Popup.name]: Popup,
    [Icon.name]: Icon,
    FooterButton,
    PlateNumber
  },
  created () {
    // console.log(this.$route)
    // 从 shop/management 页面 进入
    let { cartId, marketId, marketMerchantId, shopId, shopName, isNewGoods } = this.$route.query
    if (marketId && marketMerchantId && shopId && shopName) {
      this.sendData.farmers_markets_id = marketId
      this.sendData.farmers_market_merchant_id = marketMerchantId
      this.sendData.shop_id = shopId
      this.sendData.shop_name = shopName
      // console.log(typeof isNewGoods)
      if ((typeof isNewGoods) === 'string') {
        isNewGoods = isNewGoods === 'true'
      }
      if (isNewGoods) {
        // console.log(this.$store.state.sourceInfo)
        this.sendData = this.$store.state.sourceInfo
      }
      if (cartId && !isNewGoods) {
        this.getGoodsList(cartId, marketMerchantId)
      } else {
        this.$store.dispatch('setSourceInfo', this.sendData)
      }
    }
  },
  methods: {
    // 车牌键盘 提交按钮
    plateConfim (val) {
      // console.log(val)
      this.sendData.license_plate_number = val
      this.plateVisible = false
    },
    // 正则检测
    checkout (val) {
      // const charge = this.sendData.charge
      // this.sendData.charge = parseFloat(charge.toFixed(2))

      let field = this.sendData[val]
      // eslint-disable-next-line no-useless-escape
      field = field.toString().replace(/[^\d\.]|^\./g, '').replace(/\.{2}/g, '.').replace(/^([1-9]\d*|0)(\.\d{1,2})(\.|\d{1})?$/, '$1$2').replace(/^0\d{1}/g, '0')
      this.sendData[val] = parseFloat(field)
    },
    // 获取某次进场登记溯源信息
    getGoodsList (cartId, farmersMarketMerchantId) {
      // console.log(farmersMarketMerchantId)
      // console.log(cartId)
      this.$api.entry.sourceInfo({ cart_source_id: cartId }).then(res => {
        if (res.status === 200) {
          this.sendData = res.data
          this.sendData.cart_id = cartId
          this.sendData.farmers_market_merchant_id = farmersMarketMerchantId
          this.$store.dispatch('setSourceInfo', this.sendData)
        }
      })
    },
    // 删除 货物
    handleDelGoods (index) {
      Dialog.confirm({
        message: '确定删除该货物吗?'
      }).then(() => {
        this.sendData.source_list.splice(index, 1)
        this.$store.dispatch('setSourceInfo', this.sendData)
      }).catch(() => {
        console.log('删除失败')
      })
    },
    // 货物信息修改
    handleModifyGoods (goods, index) {
      this.$store.dispatch('setSourceInfo', this.sendData)
      // console.log('货物信息修改类型：', goods.type)
      const name = goods.type === 'meat' ? 'EntryMeat' : 'EntryVegetables'
      this.$router.push({ name: name, query: { index: index } })
    },
    // 添加 货物
    goGoods (type) {
      // console.log(this.sendData)
      this.$store.dispatch('setSourceInfo', this.sendData)
      const name = type === 0 ? 'EntryMeat' : 'EntryVegetables'
      this.$router.push({
        name: name,
        query: {
          index: null,
          shopId: this.$route.query.shopId
        }
      })
    },
    // 完成
    confirm () {
      // console.log('车辆信息提交：', this.sendData)
      const fields = this.$refs.register.querySelectorAll('.card-field')
      for (let i = 0; i < fields.length; i++) {
        const item = fields[i]
        if (item.getAttribute('required')) {
          if (this.sendData[item.getAttribute('data-label')] === '') {
            Dialog({ message: '请填写' + item.querySelector('span').innerHTML })
            return false
          }
          if (item.getAttribute('data-type') === 'number' && (typeof this.sendData[item.getAttribute('data-label')]) !== 'number') {
            console.log((typeof this.sendData[item.getAttribute('data-label')]))
            Dialog({ message: item.querySelector('span').innerHTML + '输入的值格式不对' })
            return false
          }
        }
      }

      if (this.sendData.source_list.length === 0) {
        Dialog({ message: '请添加货物信息' })
        return false
      }

      let isAdd = true
      if (this.sendData.cart_id) {
        isAdd = false
      } else {
        isAdd = true
      }

      if (isAdd) {
        this.$api.entry.add(this.sendData).then(res => {
          if (res.status === 200) {
            this.$router.replace({
              name: 'Management',
              query: {
                marketId: this.sendData.farmers_markets_id,
                marketMerchantId: this.sendData.farmers_market_merchant_id,
                shopId: this.sendData.shop_id,
                shopName: this.sendData.shop_name
              }
            })
          } else {
            // Dialog.alert(res.message)
          }
        })
      } else {
        this.$api.entry.update({
          cart_source_id: this.sendData.cart_id,
          data: this.sendData
        }).then(res => {
          // console.log(res)
          if (res.status === 200) {
            this.$router.replace({
              name: 'Management',
              query: {
                topActivetab: 0,
                marketId: this.sendData.farmers_markets_id,
                marketMerchantId: this.sendData.farmers_market_merchant_id,
                shopId: this.sendData.shop_id,
                shopName: this.sendData.shop_name
              }
            })
          } else {
            // console.log(res.message)
          }
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
@import './index.less';
.plate {
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  z-index: 100;
  font-size: 0.3rem;
}
</style>
