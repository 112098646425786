<template>
  <div id="page">
    <div class="wrap">
      <div class="head">填写车牌号</div>
      <div class="radio-box">
        <label class="flex-items-center">
          <img
            v-if="formData.commonCard == 1"
            src="../../../assets/images/icon/icon_chose_s@2x.png"
            alt=""
          />
          <img
            v-else
            src="../../../assets/images/icon/icon_chose_n@2x.png"
            alt=""
          />
          <input type="radio" v-model="formData.commonCard" value="1" />普通车牌
        </label>
        <label class="flex-items-center">
          <img
            v-if="formData.commonCard == 2"
            src="../../../assets/images/icon/icon_chose_s@2x.png"
            alt=""
          />
          <img
            v-else
            src="../../../assets/images/icon/icon_chose_n@2x.png"
            alt=""
          />
          <input
            type="radio"
            v-model="formData.commonCard"
            value="2"
          />新能源车牌
        </label>
      </div>
      <div
        class="num-box"
        :class="formData.commonCard == '2' ? 'new-plate' : ''"
      >
        <!-- <div class="focus" style="">
          <div class="line"></div>
        </div> -->
        <div class="num num0" @click="clickFirstWrap()">
          <span>{{ formData.num0 }}</span>
        </div>
        <div class="num num1" @click="clickKeyWordWrap(1)">
          <span>{{ formData.num1 }}</span>
        </div>
        <em class="spot"></em>
        <div class="num num1" @click="clickKeyWordWrap(2)">
          <span>{{ formData.num2 }}</span>
        </div>
        <div class="num num1" @click="clickKeyWordWrap(3)">
          <span>{{ formData.num3 }}</span>
        </div>
        <div class="num num1" @click="clickKeyWordWrap(4)">
          <span>{{ formData.num4 }}</span>
        </div>
        <div class="num num1" @click="clickKeyWordWrap(5)">
          <span>{{ formData.num5 }}</span>
        </div>
        <div class="num num1" @click="clickKeyWordWrap(6)">
          <span>{{ formData.num6 }}</span>
        </div>
        <div
          v-if="formData.commonCard == '2'"
          class="num num1"
          @click="clickKeyWordWrap(7)"
        >
          <span>{{ formData.num7 }}</span>
        </div>
      </div>
    </div>
    <!-- 第一个键盘 -->
    <div class="first-word-wrap" v-if="firstWrapStatus === true">
      <div
        class="word-row"
        v-for="(wordRow, index) in firstKeyWord"
        :key="index"
      >
        <div
          v-for="(word, wordIndex) in wordRow"
          :key="wordIndex"
          @click="selectFirstWord($event)"
        >
          <div class="word-wrap">
            <span class="word" v-if="word !== 'del'">{{ word }}</span>
            <span class="word word-del" v-else @click="deleteWord">
              <img
                src="../../../assets/images/icon/plate-icon-del.png"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二个键盘 start -->
    <div
      class="keyboard-wrap"
      v-if="keyBoardStatus === true && activeKeyWordIndex < 6"
    >
      <div
        class="word-row"
        v-for="(word_row, row_index) in keyWord"
        :key="row_index"
      >
        <div class="wrap" v-if="activeKeyWordIndex == 1 && row_index > 1">
          <div class="word-wrap" v-for="(word, index) in word_row" :key="index">
            <span
              @click="clickKeyBoard(word)"
              class="word"
              v-if="word !== 'del'"
              >{{ word }}</span
            >
            <span class="word word-del" v-else @click="deleteWord">
              <img
                src="../../../assets/images/icon/plate-icon-del.png"
                alt=""
              />
            </span>
          </div>
        </div>
        <div class="wrap" v-if="activeKeyWordIndex > 1 && row_index > 0">
          <div class="word-wrap" v-for="(word, index) in word_row" :key="index">
            <span
              @click="clickKeyBoard(word)"
              class="word"
              v-if="word !== 'del'"
              >{{ word }}</span
            >
            <span class="word word-del" v-else @click="deleteWord">
              <img
                src="../../../assets/images/icon/plate-icon-del.png"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      class="keyboard-wrap"
      v-if="keyBoardStatus === true && activeKeyWordIndex >= 6"
    >
      <div
        class="word-row"
        v-for="(word_row, row_index) in keyWord"
        :key="row_index"
      >
        <div class="wrap">
          <div class="word-wrap" v-for="(word, index) in word_row" :key="index">
            <span
              @click="clickKeyBoard(word)"
              class="word"
              v-if="word !== 'del'"
              >{{ word }}</span
            >
            <span class="word word-del" v-else @click="deleteWord">
              <img
                src="../../../assets/images/icon/plate-icon-del.png"
                alt=""
              />
            </span>
          </div>
        </div>
      </div>
    </div>
    <!-- 第二个键盘 end -->
    <div class="submit-box">
      <button @click="submitFn()">确认</button>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      formData: {
        commonCard: '1',
        num0: '',
        num1: '',
        num2: '',
        num3: '',
        num4: '',
        num5: '',
        num6: '',
        num7: ''
      },
      firstKeyWord: [
        ['京', '津', '渝', '沪', '冀', '晋', '辽', '吉', '黑', '苏'],
        ['浙', '皖', '闽', '赣', '鲁', '豫', '鄂', '湘', '粤', '琼'],
        ['川', '贵', '云', '陕', '甘', '青', '蒙', '桂', '宁', '新'],
        ['藏', '使', '领', '警', '学', '港', '澳', 'del']
      ],
      keyWord: [
        ['使', '领', '警', '学', '港', '澳'],
        ['1', '2', '3', '4', '5', '6', '7', '8', '9', '0'],
        ['Q', 'W', 'E', 'R', 'T', 'Y', 'U', 'O', 'P'],
        ['A', 'S', 'D', 'F', 'G', 'H', 'J', 'K', 'L'],
        ['Z', 'X', 'C', 'V', 'B', 'N', 'M', 'del']
      ],
      allKeyWord: {
        _1: [1, 2, 3, 4, 5, 6, 7],
        _2: [8, 9, 0],
        _3: ['A', 'B', 'C', 'D', 'E', 'F', 'G'],
        _4: ['H', 'J', 'K', 'L', 'M', 'N', 'O'],
        _5: ['P', 'Q', 'R', 'S', 'T', 'U', 'V'],
        _6: ['W', 'X', 'Y', 'Z'],
        _7: ['港', '澳', '学', '领', '警']
      },
      activeKeyWordIndex: 1, // 当前车牌号所在的框
      keyBoardStatus: false, // 其他输入框 弹窗
      firstWrapStatus: true, // 第一个输入框 弹窗
      submitConfirm: false,
      submitConfirmFalse: false,
      submitConfirmText: ''
    }
  },
  components: {
    // FooterButton
  },
  mounted () {
  },
  methods: {
    clickFirstWrap () {
      // 点击第一个输入框
      this.firstWrapStatus = true
      this.keyBoardStatus = false
      this.formData.num0 = ''
    },
    selectFirstWord (event) {
      console.log(event)
      // 选择省份
      if (event.target.localName !== 'span') {
        return
      }
      this.formData.num0 = event.target.innerText
      this.firstSelectStatus = true
      this.firstWrapStatus = false
      this.keyBoardStatus = true
      this.activeKeyWordIndex = 1
      // this.$refs.num1.focus()
      // document.getElementById('num1').focus()
    },
    clickKeyBoard (item) { // 点击自定义键盘
      this.formData['num' + this.activeKeyWordIndex] = item
      this.activeKeyWordIndex++
      /* if (this.formData.commonCard === '1') {
        this.activeKeyWordIndex++
        if (this.activeKeyWordIndex > 6) {
          this.keyBoardStatus = false
        }
      } else {
        this.activeKeyWordIndex++
        if (this.activeKeyWordIndex > 7) {
          this.keyBoardStatus = false
        }
      } */
    },
    deleteWord () { // 退格
      // console.log(this.activeKeyWordIndex)
      // console.log(this.formData['num' + (this.activeKeyWordIndex - 1)])
      if (this.activeKeyWordIndex > 0) {
        this.formData['num' + (this.activeKeyWordIndex - 1)] = ''
        this.activeKeyWordIndex--
      }
      if (this.activeKeyWordIndex === 1) {
        this.firstWrapStatus = true
        this.keyBoardStatus = false
      }
    },
    clickKeyWordWrap (activeKeyWordIndex) {
      if (activeKeyWordIndex) {
        this.firstWrapStatus = false
      }

      this.keyBoardStatus = true
      this.activeKeyWordIndex = activeKeyWordIndex
      this.formData['num' + this.activeKeyWordIndex] = ''
    },
    submitFn () {
      let plateLicense
      if (this.formData.commonCard === '1') {
        plateLicense = this.plate_license_1
        plateLicense = this.palindrome(plateLicense)
        if (plateLicense.length < 7) {
          alert('请输入正确的车牌号')
          return
        }
      }
      if (this.formData.commonCard === '2') {
        plateLicense = this.plate_license_2
        plateLicense = this.palindrome(plateLicense)
        if (plateLicense.length < 7) {
          alert('请输入正确的车牌号')
          return
        }
      }

      // console.log(plateLicense)
      const text = plateLicense.slice(0, 2) + '·' + plateLicense.slice(2, plateLicense.length)
      this.$emit('confim', text)
    },
    palindrome (str) {
      var arr = str.split('')
      arr = arr.filter(function (val) {
        return (
          val !== ' ' &&
          val !== ',' &&
          val !== '.' &&
          val !== '?' &&
          val !== ':' &&
          val !== ';' &&
          val !== '`' &&
          val !== "'" &&
          val !== '_' &&
          val !== '/' &&
          val !== '-' &&
          val !== '\\' &&
          val !== '' &&
          val !== '(' &&
          val !== ')'
        )
      })
      return arr.join('')
    },
    checkIsHasSpecialStr (str) {
      var flag = false
      var arr = str.split('')
      arr.forEach(val => {
        if (
          val === '!' ||
          val === '}' ||
          val === '{' ||
          val === ']' ||
          val === '[' ||
          val === '&' ||
          val === '$' ||
          val === '@' ||
          val === ' ' ||
          val === ',' ||
          val === '.' ||
          val === '?' ||
          val === ':' ||
          val === ';' ||
          val === '`' ||
          val === "'" ||
          val === '_' ||
          val === '/' ||
          val === '-' ||
          val === '\\' ||
          val === '' ||
          val === '(' ||
          val === ')'
        ) {
          flag = true
        }
      })
      return flag
    },
    checkIsHasChineseStr (str) {
      var Reg = /.*[\u4e00-\u9fa5]+.*/
      if (Reg.test(str)) {
        return true
      }
      return false
    }
  },
  computed: {
    plate_license_1 () {
      return (
        this.formData.num0 +
        this.formData.num1 +
        this.formData.num2 +
        this.formData.num3 +
        this.formData.num4 +
        this.formData.num5 +
        this.formData.num6
      )
    },
    plate_license_2 () {
      return (
        this.formData.num0 +
        this.formData.num1 +
        this.formData.num2 +
        this.formData.num3 +
        this.formData.num4 +
        this.formData.num5 +
        this.formData.num6 +
        this.formData.num7
      )
    }
  }
}
</script>
<style lang="less" scoped>
.flex-items-center {
  display: flex;
  align-items: center;
}
.wrap {
  // height: calc(100vh - 1.48rem);
  background-color: #fff;
  border-radius: 0.5rem;
  padding-bottom: 0.66rem;
  .head {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 1.1rem;
    font-weight: bold;
    font-size: 0.36rem;
    border-bottom: 0.01rem solid#E1E1E1;
  }
  .radio-box {
    padding: 0.37rem 0.3rem 0.28rem;
    display: flex;
    align-items: center;
    font-size: 0.3rem;
    text-align: right;
    color: #4a4a4a;
    input[type='radio'] {
      display: none;
    }
    label {
      // padding-left: 0.6rem;
      padding-right: 0.5rem;
      cursor: pointer;
      img {
        width: 0.3rem;
        margin-right: 0.1rem;
      }
    }
  }
  .card-header {
    font-size: 0.3rem;
    padding: 0.2rem 0.2rem 0;
    color: #4a4a4a;
  }
  // input输入框
  .num-box {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.26rem;
    // &.new-plate {
    //   padding: 0 0.2rem;
    //   .focus {
    //     left: 0.34rem;
    //   }
    // }
    .spot {
      width: 0.2rem;
      height: 0.2rem;
      border-radius: 50%;
      background-color: #d8d8d8;
    }
    & > div.num {
      width: 0.8rem;
      height: 0.8rem;
      border-radius: 0.08rem;
      border: 0.01rem solid #eaeaea;
      &.first {
        position: relative;
        text-align: center;
        line-height: 1.7rem;
        font-weight: 200;
        .input-wrap {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          &.active {
            z-index: 100;
          }
        }
        em {
          color: #979797;
          font-size: 1.6rem;
          line-height: 1.7rem;
        }
        span {
          display: inline-block;
          width: 100%;
          height: 100%;
          // background-color: #9cbce2;
          color: #828282;
          // line-height: 1.8rem;
        }
      }
      &.active {
        border: 1px solid #4a90e2;
        &:after {
          border-bottom: 0.5rem solid #4a90e2;
        }
      }
      span {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        font-size: 0.3rem;
        color: #828282;
        &.first {
          background-color: #9cbce2;
          color: #fff;
          text-indent: 0.4rem;
          border-radius: 0;
        }
      }
    }
    // .focus {
    //   display: flex;
    //   align-items: center;
    //   position: absolute;
    //   left: 0.4rem;
    //   .line {
    //     width: 0.04rem;
    //     height: 0.48rem;
    //     background: #28be9c;
    //     border-radius: 0.02rem;
    //   }
    // }
  }
  .info {
    font-size: 0.5rem;
    margin-top: 0.9rem;
    color: #828282;
    text-align: left;
    img {
      width: 0.6rem;
      vertical-align: middle;
    }
  }
}

.first-word-wrap {
  padding-bottom: 0.35rem;
  .word-row {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 0.16rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    .word {
      margin: 0 0.06rem;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 0.61rem;
      height: 0.88rem;
      background: #ffffff;
      border-radius: 0.08rem;
      border: 0.01rem solid #eaeaea;
      text-align: center;
      &.word-del {
        width: 1.34rem;
        height: 0.88rem;
      }
      img {
        width: 0.56rem;
        height: 0.56rem;
      }
    }
  }
}
.keyboard-wrap {
  padding-bottom: 0.35rem;
  .word-row {
    margin-bottom: 0.16rem;
    &:last-of-type {
      margin-bottom: 0;
    }
    & > .wrap {
      padding-bottom: 0;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .word {
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 0.06rem;
      width: 0.61rem;
      height: 0.88rem;
      background: #ffffff;
      border-radius: 0.08rem;
      border: 0.01rem solid #eaeaea;
      text-align: center;
      &.word-del {
        width: 1.34rem;
        height: 0.88rem;
      }
      img {
        width: 0.56rem;
        height: 0.56rem;
      }
    }
  }
}
.submit-box {
  border-top: 0.01rem solid #e1e1e1;
  width: 100%;
  height: 1.48rem;
  padding: 0.3rem;
  button {
    width: 100%;
    height: 0.88rem;
    background: linear-gradient(132deg, #84e9ba 0%, #00cf96 100%);
    border-radius: 0.44rem;
    font-size: 0.36rem;
    color: #fff;
  }
}
</style>
